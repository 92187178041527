import { template as template_2b6dbf31a2de4901993ecca439e1e458 } from "@ember/template-compiler";
const WelcomeHeader = template_2b6dbf31a2de4901993ecca439e1e458(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
