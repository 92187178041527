import { template as template_a17b8220a254438c97b31c4dbcc175e9 } from "@ember/template-compiler";
const FKLabel = template_a17b8220a254438c97b31c4dbcc175e9(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
