import { template as template_b763e541444845309b80bf07d269f0eb } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_b763e541444845309b80bf07d269f0eb(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
